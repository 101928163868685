import { formatTime } from '@capturi/audio'
import { CurrentUser } from '@capturi/core'
import { PlayButton } from '@capturi/ui-components'
import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { AnimatePresence, motion } from 'framer-motion'
import React, { ReactNode } from 'react'
import {
  MdComment,
  MdFlag,
  MdForward10,
  MdLibraryAdd,
  MdReplay10,
} from 'react-icons/md'

import { useFeatureFlags } from '@capturi/feature-flags'
import AnimatedCheckmark from './Checkmark.svg'
import CollapsibleButton from './CollapsibleButton'

const MotionFlex = motion(Flex)

const AudioControls: React.FC<{
  onTogglePlay: () => void
  user: CurrentUser
  isPlaying: boolean
  isAudioLoading: boolean
  time: number
  duration: number
  handleSeekBackwards: () => void
  handleSeekForward: () => void
  toggleSpeed: () => void
  playbackRate: number
  handleOpenCreateComment: () => void
  handleOpenCreateSnippet: () => void
  qaIsReviewed: boolean
  hasAudio: boolean
  qaReviewedByUserName: string | null
  qaReviewedDate: Date | null
  onToggleIsReviewed: () => void
  hasConsent: boolean
}> = ({
  onTogglePlay,
  user,
  isPlaying,
  isAudioLoading,
  time,
  duration,
  handleSeekBackwards,
  handleSeekForward,
  toggleSpeed,
  playbackRate,
  handleOpenCreateComment,
  handleOpenCreateSnippet,
  qaIsReviewed,
  hasAudio,
  onToggleIsReviewed,
  qaReviewedByUserName,
  qaReviewedDate,
  hasConsent,
}) => {
  const { isArchiveOrg } = useFeatureFlags()

  const renderIsReviewedToolTip = (): ReactNode => {
    return (
      <Box>
        <Trans>Reviewed by {qaReviewedByUserName}</Trans>
        <Box>{qaReviewedDate?.toLocaleString()}</Box>
      </Box>
    )
  }

  const playbackDisabled = !(user.permissions.playback && hasAudio)

  return (
    <>
      <Flex
        justify="space-between"
        align="center"
        px="5"
        py="0.875rem"
        bg="transparent"
        borderWidth={1}
        borderRadius={'0px 0px 10px 10px'}
        borderColor="border.light"
        mb={4}
      >
        <Flex align="center">
          <Box display="inline-block">
            <PlayButton
              onClick={onTogglePlay}
              isPlaying={isPlaying}
              size="sm"
              isLoading={isAudioLoading}
              useSpeakerIcon={false}
              hasAudio={hasAudio}
              isPlaybackAllowed={user.permissions.playback}
            />
          </Box>
          {hasAudio ? (
            <Box>
              <Box
                display="inline-block"
                mx={4}
                color={playbackDisabled ? 'gray.500' : undefined}
              >
                {`${formatTime(time)} / ${formatTime(duration)}`}
              </Box>
              <Flex align="center" display="inline-block">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={handleSeekBackwards}
                  px="0"
                  isDisabled={!user.permissions.playback}
                  aria-label={t`Rewind 10 seconds`}
                  title={t`Rewind 10 seconds`}
                >
                  <Icon as={MdReplay10} boxSize="20px" />
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={handleSeekForward}
                  px="0"
                  isDisabled={!user.permissions.playback}
                  title={t`Forward 10 seconds`}
                  aria-label={t`Forward 10 seconds`}
                  mx="2"
                >
                  <Icon as={MdForward10} boxSize="20px" />
                </Button>
                <Button
                  variant="ghost"
                  size="xs"
                  isDisabled={!user.permissions.playback}
                  onClick={toggleSpeed}
                  title={t`Toggle playback speed`}
                  aria-label={t`Toggle playback speed`}
                >
                  {playbackRate}x
                </Button>
              </Flex>
            </Box>
          ) : !hasConsent ? (
            <Text fontSize="sm" mx="2">
              <Trans>The customer did not provide consent for recording</Trans>
            </Text>
          ) : (
            <Text fontSize="sm" mx="2">
              <Trans>Audio on this conversation has been removed</Trans>
            </Text>
          )}
        </Flex>
        {!isArchiveOrg && (
          <Box>
            <MotionFlex
              justifyContent="end"
              layoutRoot
              gap={1}
              align="center"
              initial={false}
              layout={false}
            >
              <AnimatePresence mode="popLayout">
                {user.permissions.qualityAssurance && (
                  <motion.div key="mark-as-reviewed" initial={false} layout>
                    <CollapsibleButton
                      iconSize={qaIsReviewed ? '22px' : undefined}
                      onClick={onToggleIsReviewed}
                      icon={qaIsReviewed ? AnimatedCheckmark : MdFlag}
                      label={qaIsReviewed ? t`Reviewed` : t`Review`}
                      tooltip={
                        qaIsReviewed ? renderIsReviewedToolTip() : undefined
                      }
                    />
                  </motion.div>
                )}
                <motion.div key="add-comment" initial={false} layout>
                  <CollapsibleButton
                    onClick={handleOpenCreateComment}
                    icon={MdComment}
                    label={t`Add comment`}
                  />
                </motion.div>

                {hasAudio && (
                  <motion.div key="add-to-library" initial={false} layout>
                    <CollapsibleButton
                      onClick={handleOpenCreateSnippet}
                      icon={MdLibraryAdd}
                      label={t`Add to library`}
                      isDisabled={
                        !(
                          user.permissions.playback &&
                          user.permissions.editLibrary
                        )
                      }
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </MotionFlex>
          </Box>
        )}
      </Flex>
    </>
  )
}

export default AudioControls
